

$.fn.exists = function () {
    return this.length > 0;
};
$('[data-toggle="tooltip"]').tooltip();

function initCircle() {
    const circleBar = $('.circular__bar');
    const trackColor = '#ecf0f6';
    const trackLine = 'square';


    if (circleBar.exists()) {
        $('.circular__bar').each(function () {
            console.log('done');
            $(this).easyPieChart({
                barColor: '#c2ff1f',
                trackColor: trackColor,
                lineCap: trackLine,
                lineWidth: 8,
                size: 90,
                scaleLength: 0
            });
        })

    }

};
initCircle()